import Logo from "../../assets/logo-IM-01.png";
import { useState } from "react";
import Colocaciones from "./component/Colocaciones";
import Resumen from "./component/Resumen";
import News from "../news/News";
import Seguros from "../seguros";
import SegurosGenerales from "../seguros/SegurosGenerales";
import RentabilidadBanca from "../RentabilidadBanca/RentabilidadBanca";
import ResumenGeneral from "../ResumenGeneral/ResumenGeneral";
import Otros from "../otros/Otros";
import ImportesExterior from "../ImportesExterior/ImportesExterior";

const menu = [
  {
    titulo: "Resumen General",
    attr: "resumenGeneral",
  },
  {
    titulo: "Resumen Banca",
    attr: "resumen",
  },
  {
    titulo: "Colocaciones Banca",
    attr: "colocaciones",
  },
  {
    titulo: "Colocaciones Exterior",
    attr: "ImportesExterior",
  },
  {
    titulo: "Seguros Generales",
    attr: "segurosG",
  },
  {
    titulo: "Seguros de Vida",
    attr: "seguros",
  },
  {
    titulo: "Consolidados Noticias",
    attr: "news",
  },
  {
    titulo: "Rentabilidades Banca",
    attr: "RentabilidadBanca",
  },

  {
    titulo: "Otros",
    attr: "otros",
  },
];

export default function Pages() {
  const [show, setShow] = useState({
    resumen: false,
    colocaciones: false,
    seguros: false,
    news: false,
    segurosG: false,
    RentabilidadBanca: false,
    resumenGeneral: true,
    otros: false,
    ImportesExterior: false,
  });

  const clickShow = (e) => {
    const menu = e.currentTarget.getAttribute("data-menu");
    const newShow = { ...show };

    Object.entries(show).forEach(([key]) => {
      if (key !== menu) {
        newShow[key] = false;
      }
      if (key === menu) {
        newShow[key] = true;
      }
    });

    setShow(newShow);
  };

  return (
    <div className="m-auto max-w-4xl flex flex-col justify-center gap-10   mt-10 shadow-2xl p-6">
      <div className="flex items-center justify-between gap ">
        <img src={Logo} className="w-40 h-28" alt="logo" />
        <div className="flex flex-col gap-2">
          <p className="text-xs tracking-tight">
            <span className="font-semibold">Data CMF:</span> Diciembre 2024. bancos
          </p>
          <p className="text-xs tracking-tight">
            <span className="font-semibold">Data Seguros:</span> Septiembre 2024.
          </p>
          <p className="text-xs tracking-tight">
            <span className="font-semibold">Data Odepa:</span> Diciembre 2024.
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-3 h-20  bg-c-primary"></div>
        <div>
          <h2 className="text-3xl font-semibold">Informe de Directorio</h2>
          <h3 className="text-2xl">Empresas JY</h3>
        </div>
      </div>
      <div className="flex items-stretch gap-2 justify-around flex-wrap">
        {menu.map((element) => (
          <div
            key={element.titulo}
            className={`cursor-pointer ${
              show[element.attr] ? "bg-c-primary" : "bg-black"
            }`}
            style={{
              color: "white",
              padding: "10px",
            }}
            onClick={clickShow}
            data-menu={element.attr}
          >
            <p>{element.titulo}</p>
          </div>
        ))}
      </div>
      {show.resumen && (
        <>
          <Resumen />
        </>
      )}

      {show.colocaciones && (
        <>
          <Colocaciones />
        </>
      )}
      {show.seguros && (
        <>
          <Seguros />
        </>
      )}
      {show.segurosG && (
        <>
          <SegurosGenerales />
        </>
      )}

      {show.news && (
        <>
          <News />
        </>
      )}

      {show.RentabilidadBanca && (
        <>
          <RentabilidadBanca />
        </>
      )}

      {show.ImportesExterior && <ImportesExterior />}

      {show.resumenGeneral && <ResumenGeneral />}
      {show.otros && <Otros />}
    </div>
  );
}
